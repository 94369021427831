import React from "react"
import cuid from "cuid"
import { Row, Col } from "react-styled-flexboxgrid"
import styled from "styled-components"
import Gallery from "react-photo-gallery"
import { navigate } from "gatsby"
import { Parallax, Primary, Alternate } from "../Section"
import { ButtonGhost } from "../Button"
import HeroHome from "../../images/hero-home.jpg"
import c_alpharetta_lighting from "../../images/events/c_alpharetta_lighting.jpg"
import c_columbia_snowfest from "../../images/events/c_columbia_snowfest.jpg"
import c_corrider from "../../images/events/c_corrider.jpg"
import c_day_with_santa from "../../images/events/c_day_with_santa.jpg"
import c_music_christmas from "../../images/events/c_music_christmas.jpg"
import c_rail_jam1 from "../../images/events/c_rail_jam1.jpg"
import c_unleashed from "../../images/events/c_unleashed.jpg"

const events = [
  {
    id: cuid(),
    src: c_alpharetta_lighting,
    sizes: ["(min-width: 480px) 50vw", "(min-width: 1024px) 33.3vw", "100vw"],
    width: 4,
    height: 4,
    alt: "Alpharetta Lighting ",
  },
  {
    id: cuid(),
    src: c_columbia_snowfest,
    sizes: ["(min-width: 480px) 50vw", "(min-width: 1024px) 33.3vw", "100vw"],
    width: 4,
    height: 4,
    alt: "Columbia Snowfest ",
  },
  {
    id: cuid(),
    src: c_corrider,
    sizes: ["(min-width: 480px) 50vw", "(min-width: 1024px) 33.3vw", "100vw"],
    width: 4,
    height: 4,
    alt: "Corrider ",
  },
  {
    id: cuid(),
    src: c_day_with_santa,
    sizes: ["(min-width: 480px) 50vw", "(min-width: 1024px) 33.3vw", "100vw"],
    width: 4,
    height: 4,
    alt: "Day with Santa ",
  },
  {
    id: cuid(),
    src: c_music_christmas,
    sizes: ["(min-width: 480px) 50vw", "(min-width: 1024px) 33.3vw", "100vw"],
    width: 4,
    height: 4,
    alt: "Music Christmas",
  },
  {
    id: cuid(),
    src: c_rail_jam1,
    sizes: ["(min-width: 480px) 50vw", "(min-width: 1024px) 33.3vw", "100vw"],
    width: 4,
    height: 4,
    alt: "Rail Jam",
  },
  {
    id: cuid(),
    src: c_unleashed,
    sizes: ["(min-width: 480px) 50vw", "(min-width: 1024px) 33.3vw", "100vw"],
    width: 4,
    height: 4,
    alt: "Unleashed",
  },
]

const WhiteSpace = styled.hr`
  background-color: transparent;
  margin-top: 4rem;
`
const Home = () => {
  return (
    <section>
      <Parallax centered backgroundImage={HeroHome} backgroundHeight={650}>
        <WhiteSpace />
        <h1>Snow Making in Atlanta</h1>
        <h4>We make real snow, anywhere, in any climate.</h4>

        <ButtonGhost onClick={() => navigate("/contact")}>
          Contact Us
        </ButtonGhost>
      </Parallax>
      <Primary centered>
        <Row>
          <Col xs={12}>
            <h3 className="text-center">
              Check out these events using Iceman!
            </h3>
            <Gallery photos={events} />
          </Col>
        </Row>
      </Primary>
    </section>
  )
}

export default Home
